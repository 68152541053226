import React, { useContext } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Logo from '../assets/haba logo.png';

import Logout from '../assets/logout.svg';
import Profile from '../assets/profile.png';
import ArrowRight from '../assets/arrow-right.svg';
import { UserContext } from '../services/userContext';
import MenuClose from '../assets/menu-close.svg';
import { FaBeer } from 'react-icons/fa';
import { FaUserFriends } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { MdOutlineSupportAgent } from "react-icons/md";
import { AiTwotoneSafetyCertificate } from "react-icons/ai";
import { RiFileListFill } from "react-icons/ri";






const Sidebar = ({ handleClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useContext(UserContext);

  const logout = () => {
    if (localStorage.getItem('userToken')) {
      localStorage.clear('userToken');
    }
    navigate('/auth/login');
  };

  const currentPath = location.pathname;
  const splitPath = currentPath.split('/');

  return (
    <>
      <div className='transition ease-in-out delay-150 pt-6 lg:pt-6 pb-4 bg-[#fff] flex flex-col h-full fixed '>
        <div className='first-section px-4'>
          <div className='profile-details flex mb-10  items-center justify-between '>
            <Link to='/'>
              <img
                className='w-[120px]'
                src={Logo}
                alt='Company Logo'
                srcset=''
              />
            </Link>

            <div
              onClick={handleClick}
              className='close-icon block text-lg lg:hidden'
            >
              <img className='w-10' src={MenuClose} alt='' srcset='' />
            </div>
          </div>

          <div className='nav-items text-white space-y-6 mb-10'>
            <Link
              className={`${
                splitPath[1] === 'dashboard'
                  ? 'bg-primary text-[#ffffff]'
                  : 'text-[#6D6D7A] '
              } nav-item  flex items-center gap-2 p-3 rounded-md  hover:text-[#fff] hover:bg-primary `}
              to='/'
            >
              <MdSpaceDashboard />
              Dashboard
            </Link>

            <Link
              className={`${
                splitPath[1] === 'users'
                  ? 'bg-primary text-[#ffffff]'
                  : 'text-[#6D6D7A] '
              } nav-item  flex items-center gap-2 p-3 rounded-md  hover:text-[#fff] hover:bg-primary `}
              to='/users'
            >
              <FaUserFriends />
              User Management
            </Link>

            <Link
              className={`${
                splitPath[1] === 'sub-agent'
                  ? 'bg-primary text-[#ffffff]'
                  : 'text-[#6D6D7A] '
              } nav-item  flex items-center gap-2 p-3 rounded-md  hover:text-[#fff] hover:bg-primary `}
              to='/sub-agent'
            >
              <MdOutlineSupportAgent />
              Sub Agents
            </Link>

            <Link
              className={`${
                splitPath[1] === 'vendors'
                  ? 'bg-primary text-[#ffffff]'
                  : 'text-[#6D6D7A] '
              } nav-item  flex items-center gap-2 p-3 rounded-md  hover:text-[#fff] hover:bg-primary `}
              to='/vendors'
            >
              <AiTwotoneSafetyCertificate />
              Vendors
            </Link>

            <Link
              className={`${
                splitPath[1] === 'coverlist'
                  ? 'bg-primary text-[#ffffff]'
                  : 'text-[#6D6D7A] '
              } nav-item  flex items-center gap-2 p-3 rounded-md  hover:text-[#fff] hover:bg-primary `}
              to='/coverlist'
            >
              <RiFileListFill />
              Policy Lists
            </Link>
          </div>
        </div>

        <div className='mb-6 h-[0.5px] bg-[#6D6D7A]/30' />

        <div className=' px-4  '>
          <button
            className='bg-primary p-2  rounded-[8px]  w-full flex items-center justify-center gap-2'
            onClick={logout}
          >
            <img src={Logout} alt='' srcset='' className='w-5' />
            <span className=' text-[16px] text-[#fff] font-medium '>
              Logout
            </span>
          </button>
        </div>

        <div
          className='px-4 mt-auto py-6'
          // onClick={handleProfileCard}
        >
          <div className='bg-[#fff] pl-4 py-2 rounded-[8px]  shadow-md flex items-center gap-2 '>
            <div className='relative cursor-pointer'>
              <img
                src={Profile}
                alt='ProfilePicture'
                className='w-[35px] h-[35px] rounded-full '
              />
              <div className='absolute -bottom-1 right-0 w-4 h-4 rounded-full bg-[#03b574] animate-pulse'></div>
            </div>

            <div className='flex flex-col w-[150px]'>
              <h1 className='text-[14px] text-[#333] font-medium '>
                {user?.first_name} {user?.last_name}
              </h1>
              <p className='text-[12px] text-[#666666] '>{user?.email}</p>
            </div>

            <img src={ArrowRight} alt='' srcset='' className='w-4' />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
