import 'intl';
import 'intl/locale-data/jsonp/en';

// const Home = "http://127.0.0.1:8000/api/";
// const Home = 'https://test.haba.insure/api/';
const baseURL = 'https://api.haba.insure/api/';
const flutterKey = 'FLWPUBK_TEST-e7f283ff723b20e7857a0b6acba4021a-X';

const formatAmount = (amount, maxDigits = 8) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN',
    maximumSignificantDigits: maxDigits,
  }).format(amount) + '.00';

export { formatAmount, baseURL, flutterKey };
